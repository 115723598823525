import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

declare var jQuery;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  MSG = 'Please try again later or contact Ask JPMC if problem persists.';

  constructor(private sanitizer: DomSanitizer) {
  }

  alert(msg: string, result: { code: number, ref: string } = null) {
    jQuery('#error-msg').html(this.sanitizer.sanitize(SecurityContext.HTML, msg || this.MSG));

    if (result) {
      jQuery('#error-code').html(result.code);
      jQuery('#error-ref').html(this.sanitizer.sanitize(SecurityContext.HTML,result.ref));
      jQuery('#error-result').show();
    } else {
      jQuery('#error-result').hide();
    }

    jQuery('#app-toast').toast('show');
  }

  hide() {
    jQuery('#app-toast').toast('hide');
  }
}
