<div class="d-flex flex-column align-items-center">
  <header class="tux_header blue mb-5" role="banner">
    <div class="left_side">
      <span class="header_title">Manage Password</span>
      <nav aria-label="header in blue" class="tux_header_menu_nav">
        <ul class="tux_header_menu">
          <li><a class="menu_label" href="{{mpp_url}}">Reset & Unlock Account</a></li>
          <li><a class="menu_label" #landingPageRouterLink="routerLinkActive" routerLinkActive="active" routerLink="assistance" [attr.aria-current]="landingPageRouterLink.isActive ? 'true' : undefined">Token Assistance</a></li>
          <li><a class="menu_label" #helpDeskPageRouterLink="routerLinkActive" routerLinkActive="active" routerLink="askjpmc" [attr.aria-current]="helpDeskPageRouterLink.isActive ? 'true' : undefined">Ask JPMC</a></li>
        </ul>
      </nav>
    </div>
  </header>
<!--  <div class="flex-expand" style="min-height: 100px !important;"></div>-->
  <main class="portal-content" style="margin: auto !important;">
    <router-outlet></router-outlet>
  </main>
  <div class="flex-expand" style="min-height: 20px !important;"></div>
  <footer class="footer d-flex flex-row" role="contentinfo" aria-label="Footer">
    <img id="jpm_logo" alt="JPM Logo" class="align-self-center" style="height: 14px !important;" src="assets/jpmc_logo_black_b.png">
    <div class="flex-expand"></div>
    <div class="align-self-center">&copy;{{currentYear}} JPMorgan Chase & Co. All rights reserved.</div>
  </footer>
</div>

<!-- toast -->
<div id="app-toast" class="bg-danger toast position-fixed hide" role="alert" aria-live="polite" aria-atomic="true" data-autohide="false">
  <div class="d-flex flex-row justify-content-center m-2">
    <div class="fa fa-exclamation-circle align-self-center mr-2" style="font-size: 18px !important;"></div>
    <strong class="mr-auto align-self-center">Unable to process this request</strong>
    <button type="button" class="toast_close ml-2" data-dismiss="toast" aria-label="Close">
      <span aria-hidden="true" style="color: #fff;">&times;</span>
    </button>
  </div>
  <div class="toast-body">
    <span id="error-msg"></span>
    <br>
    <dl class="row mt-3" id="error-result">
      <dt class="col-sm-4 white-font">Error Code:</dt><dd class="col-sm-8" id="error-code"></dd>
      <dt class="col-sm-4 white-font">Reference:</dt><dd class="col-sm-8" id="error-ref" style="word-wrap: break-word;"></dd>
    </dl>
  </div>
</div>
