<div id="helpdesk">
  <div id="helpdesk-content">
    <h1>Ask JPMC</h1>
    <p class="semibold">Ask JPMC can assist you with</p>
    <ul>
      <li>Password Reset</li>
      <li>Account Unlock</li>
      <li>Provide a temporary access code</li>
      <li>Security Question Validation</li>
    </ul>
    <div id="helpdeskNumbers" class="mt-5">
      <p>
        The phone numbers listed can only be called from the country indicated. Hours of Operation 24x7.
        <br/>
        Charges may vary based on options selected.
      </p>

      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="col"><h3 class="region semibold">NAMR</h3></th>
          </tr>
          <tr>
            <td class="phone-numbers-row">
              <ul class="phone-numbers">
                <li><b>U.S. (Toll-free):</b> 1-844-ASK-JPMC (844-275-5762)</li>
                <li><b>U.S. (Local):</b> 1-212-622-9599</li>
                <li><b>Outside U.S. (Local):</b> 1-212-552-5100</li>
                <li><b>Canada:</b> 1-212-622-9599</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="col"><h3 class="region semibold">APAC</h3></th>
          </tr>
          <tr>
            <td class="phone-numbers-row">
              <ul class="phone-numbers">
                <li><b>Australia (Toll-free):</b> 1-800-818-574</li>
                <li><b>Hong Kong (Local):</b> 3077-5325</li>
                <li><b>India (Local):</b> 000-800-440-5210</li>
                <li><b>Indonesia (Local):</b> 001-803-442-501</li>
                <li><b>Japan (Local):</b> 0120-626-357</li>
                <li><b>Korea (Local):</b> 0800-018-442</li>
                <li><b>Malaysia (Local):</b> 603-7724-3007</li>
                <li><b>Malaysia (Toll-free):</b> 1-800-888-207</li>
                <li><b>Philippines (PLDT Toll-free):</b> 1-800-1441-0883</li>
                <li><b>Philippines (Globe Telecom):</b> 1-800-8908-8266</li>
                <li><b>Philippines (Local / Alternate line):</b> 0018-4762-22565</li>
                <li><b>Singapore (Toll-free):</b> 1-800-622-1944</li>
                <li><b>Taiwan (Local):</b> 0800-868-856</li>
                <li><b>Thailand (Local):</b> 66-2-684-2417</li>
                <li><b>Vietnam (Local):</b> 888-387-164</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="col"><h3 class="region semibold">EMEA</h3></th>
          </tr>
          <tr>
            <td class="phone-numbers-row">
              <ul class="phone-numbers">
                <li><b>Poland (Toll-free):</b> 800-080-595</li>
                <li><b>UK, Channel Islands Support IDD (Local):</b> +44-207-134-0606</li>
                <li><b>Ireland (Local):</b> +353-1-612-3999</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="col"><h3 class="region semibold">LATAM</h3></th>
          </tr>
          <tr>
            <td class="phone-numbers-row">
              <ul class="phone-numbers">
                <li><b>Argentina (Local):</b> +54-2822-1365</li>
                <li><b>Bahamas (Local):</b> +1-242-356-8216</li>
                <li><b>Brazil (Local):</b> +55-11-4950-4145</li>
                <li><b>Chile (Local):</b> +56-22-425-5207</li>
                <li><b>Colombia (Local):</b> +57-601-326-9736</li>
                <li><b>Mexico (Local):</b> +52-55-5540-9428</li>
                <li><b>Panama (Local):</b> +507-307-9704</li>
                <li><b>Peru (Local):</b> +51-1-205-3798</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="col"><h3 class="region semibold">For all other countries or if you are calling
              from <i>outside</i> your home country: +1-847-622-2565</h3></th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

